<script>
const url = {
  api: "http://127.0.0.1:8080",
  static: "https://static.tally.ltd",
  http: "http://127.0.0.1",
  https: "https://127.0.0.1",
};
const title = "云记贝在线记账平台";
const adminTitle = "云记贝后台管理系统";
const rules = {
  isEmail: (value) => !value || /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value) || "邮箱格式有误",
  isInteger: (value) => !value || /^\d+$/.test(value) || "只能输入整数",
  isFloat: (value) =>
      !value ||
      /^(-)?(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(value) ||
      "请输入正确格式的数字",
  isPositive: (value) =>
      !value ||
      /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(value) ||
      "请输入正确格式的正数",
  isNegative: (value) =>
      !value ||
      /^-(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(value) ||
      "请输入正确格式的负数",
  isUsername: (value) => {
    if (!value) {
      return "请输入用户名";
    }
    if (!/^[a-zA-Z0-9_]{1,30}$/.test(value)) {
      return "用户名格式有误";
    }
    return true;
  },
  isPassword: (value) => {
    if (!value) {
      return "请输入密码";
    }
    if (value.length < 8 || value.length > 30) {
      return "密码不能少于8位或大于30位";
    }
    return true;
  },
  isPhoneNumber: (value) => {
    if (!value) {
      return true;
    }
    if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
      return "手机号格式不正确";
    }
    return true;
  },
};
const enums = {
  requestMethod: [
    {text: "*", value: "*"},
    {text: "GET", value: "GET"},
    {text: "POST", value: "POST"},
    {text: "PUT", value: "PUT"},
    {text: "DELETE", value: "DELETE"},
  ],
  userStatus: [
    {text: "正常", value: "NORMAL"},
    {text: "禁用", value: "PROHIBIT"},
  ],
  feedbackStatus: [
    {text: "已创建", value: "CREATED"},
    {text: "处理中", value: "PROCESSING"},
    {text: "处理完成", value: "FINISHED"},
  ],
  feedbackType: [
    {text: "问题反馈", value: "PROBLEM"},
    {text: "网站建议", value: "SUGGESTION"},
  ],
  billFlow: [
    {text: "收入", value: "IN"},
    {text: "支出", value: "OUT"},
    {text: "转账", value: "TRANSFER"},
  ],
  accountType: [
    {text: "资金账户", value: "CAPITAL"},
    {text: "信用账户", value: "CREDIT"},
    {text: "充值账户", value: "RECHARGE"},
    {text: "理财账户", value: "FINANCIAL"},
    {text: "应收账户", value: "RECEIVABLE"},
    {text: "应付账户", value: "PAYABLE"},
  ],
  page: [5, 15, 50, 100],
};
const images = {
  profile:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/profile.svg",
  pageNotFound:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/pageNotFound.svg",
  signUp:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/signUp.svg",
  welcome:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/welcome.svg",
  forgot:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/forgot.svg",
  dataReport:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/dataReport.svg",
  noteList:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/noteList.svg",
  wallet:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/images/wallet.svg",
  police:
      "https://cdn.jsdelivr.net/gh/LuShan123888/Tally-Frontend@gh-pages/static/icons/police.png",
};
const icons = {
  accountType: [
    "wallet",
    "wallet-giftcard",
    "wallet-membership",
    "wallet-outline",
    "wallet-plus",
    "wallet-plus-outline",
    "wallet-travel",
    "bank",
    "bank-check",
    "bank-minus",
    "bank-off",
    "bank-off-outline",
    "bank-plus",
    "bank-remove",
    "bank-transfer",
    "bank-transfer-in",
    "bank-transfer-out",
    "piggy-bank",
    "piggy-bank-outline",
    "credit-card",
    "credit-card-check",
    "credit-card-check-outline",
    "credit-card-chip",
    "credit-card-chip-outline",
    "credit-card-clock",
    "credit-card-clock-outline",
    "credit-card-edit",
    "credit-card-edit-outline",
    "credit-card-fast",
    "credit-card-fast-outline",
    "credit-card-lock",
    "credit-card-lock-outline",
    "credit-card-marker",
    "credit-card-marker-outline",
    "credit-card-minus",
    "credit-card-minus-outline",
    "credit-card-multiple",
    "credit-card-multiple-outline",
    "credit-card-off",
    "credit-card-off-outline",
    "credit-card-outline",
    "credit-card-plus",
    "credit-card-plus-outline",
    "credit-card-refresh",
    "credit-card-refresh-outline",
    "credit-card-refund",
    "credit-card-refund-outline",
    "credit-card-remove",
    "credit-card-remove-outline",
    "credit-card-scan",
    "credit-card-scan-outline",
    "credit-card-search",
    "credit-card-search-outline",
    "credit-card-settings",
    "credit-card-settings-outline",
    "credit-card-sync",
    "credit-card-sync-outline",
    "credit-card-wireless",
    "credit-card-wireless-outline",
    "credit-card-wireless-off",
    "credit-card-wireless-off-outline",
    "cash",
    "cash-100",
    "cash-multiple",
    "currency-usd",
    "currency-usd-off",
    "account-cash",
    "account-cash-outline",
    "wechat",
    "qqchat",
    "home-analytics",
    "home",
    "home-heart",
    "home-floor-0",
    "home-floor-1",
    "home-floor-2",
    "home-floor-3",
    "home-group",
    "home-group-minus",
    "home-group-plus",
    "home-group-remove",
    "hospital",
    "hospital-box",
    "hospital-box-outline",
    "hospital-building",
    "bus",
    "apple",
    "apple-icloud",
    "application",
    "cellphone",
    "cellphone-basic",
    "cellphone-dock",
    "gold",
    "podium-gold",
    "bitcoin",
    "currency-btc",
    "hand-coin",
    "hand-coin-outline",
    "chart-arc",
    "chart-areaspline",
    "chart-areaspline-variant",
    "chart-bar",
    "chart-bar-stacked",
    "chart-bell-curve",
    "chart-bell-curve-cumulative",
    "chart-box",
    "chart-box-outline",
    "chart-box-plus-outline",
    "chart-gantt",
    "chart-waterfall",
    "finance",
    "help",
  ],
  billType: [
    "rice",
    "barley",
    "food-drumstick",
    "candy",
    "food",
    "food-outline",
    "food-apple",
    "food-apple-outline",
    "food-fork-drink",
    "food-takeout-box",
    "food-takeout-box-outline",
    "food-turkey",
    "food-variant",
    "hamburger",
    "noodles",
    "pasta",
    "coffee",
    "coffee-outline",
    "cup-water",
    "watering-can",
    "watering-can-outline",
    "beer",
    "beer-outline",
    "cup",
    "cup-outline",
    "glass-cocktail",
    "glass-flute",
    "glass-mug-variant",
    "glass-mug",
    "glass-tulip",
    "glass-wine",
    "fruit-cherries",
    "fruit-citrus",
    "fruit-grapes",
    "fruit-grapes-outline",
    "fruit-pineapple",
    "fruit-watermelon",
    "basket",
    "basket-outline",
    "cart-arrow-down",
    "cart-arrow-right",
    "cart-arrow-up",
    "cart-heart",
    "cart",
    "cart-outline",
    "shopping",
    "shopping-outline",
    "store",
    "hanger",
    "paper-roll",
    "paper-roll-outline",
    "store-outline",
    "store-24-hour",
    "store-settings",
    "store-settings-outline",
    "cellphone",
    "cellphone-dock",
    "cellphone-link",
    "devices",
    "deskphone",
    "earbuds",
    "microphone",
    "microphone-outline",
    "microphone-variant",
    "headphones",
    "headphones-bluetooth",
    "headphones-box",
    "phone",
    "monitor-cellphone",
    "phone-classic",
    "tablet-cellphone",
    "laptop",
    "desktop-classic",
    "apple-icloud",
    "apple-ios",
    "car-wrench",
    "hammer-wrench",
    "pipe-wrench",
    "progress-wrench",
    "tools",
    "wrench",
    "wrench-outline",
    "greenhouse",
    "home",
    "home-outline",
    "home-circle",
    "home-circle-outline",
    "home-city",
    "home-variant",
    "home-variant-outline",
    "flash",
    "home-battery",
    "home-battery-outline",
    "home-lightning-bolt",
    "home-lightning-bolt-outline",
    "lightning-bolt",
    "lightning-bolt-circle",
    "lightning-bolt-outline",
    "transmission-tower",
    "water",
    "water-circle",
    "water-boiler",
    "water-sync",
    "water-pump",
    "fire",
    "fire-circle",
    "gas-cylinder",
    "gas-station",
    "gas-station-outline",
    "router-network",
    "router-wireless",
    "access-point-network",
    "network-strength-4",
    "ip-network",
    "ip-network-outline",
    "wan",
    "wifi",
    "wifi-strength-4",
    "wifi-strength-outline",
    "google-controller",
    "gamepad-variant",
    "car",
    "car-outline",
    "car-hatchback",
    "car-back",
    "car-side",
    "car-sports",
    "taxi",
    "hail",
    "fence",
    "train",
    "train-variant",
    "train-car-passenger",
    "train-car-passenger-variant",
    "bicycle",
    "bicycle-basket",
    "bike",
    "motorbike",
    "airplane",
    "airplane-landing",
    "airplane-takeoff",
    "bus",
    "bus-school",
    "bus-side",
    "bus-stop",
    "sim",
    "sim-outline",
    "dump-truck",
    "truck-flatbed",
    "truck",
    "face-woman",
    "face-woman-outline",
    "face-woman-shimmer",
    "face-woman-shimmer-outline",
    "movie",
    "movie-roll",
    "theater",
    "shield",
    "shield-outline",
    "hospital",
    "hospital-box",
    "hospital-box-outline",
    "hospital-building",
    "needle",
    "pill",
    "doctor",
    "account-group",
    "party-popper",
    "school",
    "school-outline",
    "town-hall",
    "book",
    "book-account",
    "book-open-variant",
    "book-education",
    "book-education-outline",
    "notebook",
    "book-open-blank-variant",
    "gift",
    "gift-outline",
    "wallet-giftcard",
    "wallet-travel",
    "bed",
    "bed-outline",
    "image-filter-hdr",
    "swim",
    "baseball",
    "baseball-bat",
    "football-australian",
    "baby-bottle",
    "baby-bottle-outline",
    "baby-buggy",
    "paw",
    "dog",
    "dog-side",
    "bank",
    "bank-transfer-in",
    "bank-transfer-out",
    "bank-transfer",
    "bank-outline",
    "piggy-bank",
    "piggy-bank-outline",
    "card",
    "card-bulleted-outline",
    "card-text",
    "credit-card-chip",
    "credit-card-chip-outline",
    "credit-card",
    "credit-card-outline",
    "cash",
    "cash-refund",
    "cash-100",
    "cog",
    "cog-outline",
    "bitcoin",
    "hand-coin",
    "hand-coin-outline",
    "chart-arc",
    "chart-areaspline",
    "chart-areaspline-variant",
    "chart-bar",
    "chart-line",
    "chart-multiple",
    "chart-timeline-variant",
    "chart-timeline-variant-shimmer",
    "chart-waterfall",
    "finance",
    "email-variant",
    "email-open",
    "email-open-outline",
    "wallet-plus",
    "cash-refund",
    "credit-card-refund",
    "credit-card-refund-outline",
    "help",
  ],
};
const colors = [
  [
    "#F44336",
    "#FF9800",
    "#FFEB3B",
    "#4CAF50",
    "#009688",
    "#00BCD4",
    "#03A9F4",
    "#2196F3",
    "#3F51B5",
  ],
  [
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#FFEB3B",
    "#FF9800",
    "#F44336",
  ],
];
export default {
  url,
  title,
  adminTitle,
  images,
  rules,
  enums,
  icons,
  colors,
};
</script>